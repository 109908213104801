import React from 'react';
import { PropsWithClassName } from '@Components/helper';
import { AtomicWave, RadioSignal } from './PulseLoader.styles';
interface Props extends PropsWithClassName {
  type: 'atomicWave' | 'radioSignal';
}
const PulseLoader = (props: Props): React.ReactElement => {
  const {
    type
  } = props;
  switch (type) {
    case 'atomicWave':
      return <AtomicWave className={props.className} data-testid={props.dataTestId ?? 'atomic-wave-loader-root'} />;
    case 'radioSignal':
      return <RadioSignal className={props.className} data-testid={props.dataTestId ?? 'radio-signal-loader-root'} />;
  }
};
export default PulseLoader;