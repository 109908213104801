import styled, { keyframes } from 'styled-components';

import { adjustAlpha } from '@Components/helper/styleHelper';

const radioSignal = keyframes`
    100% {
        box-shadow: 0 0 0 18px #0000;
    }
`;

export const RadioSignal = styled.div`
  margin-right: ${(props) => props.theme.space.default_16};

  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => adjustAlpha(props.theme.palette.spinner.default, 1)};
  box-shadow: 0 0 0 0 ${(props) => adjustAlpha(props.theme.palette.spinner.default, 0.4)};
  animation: ${radioSignal} 1.5s infinite linear;
  position: relative;

  transition:
    background-color ${(props) => props.theme.transition.theme},
    box-shadow ${(props) => props.theme.transition.theme};

  &:before,
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 ${(props) => adjustAlpha(props.theme.palette.spinner.default, 0.4)};
    animation: inherit;
    animation-delay: -0.5s;
  }

  &:after {
    animation-delay: -1s;
  }
`;

const atomicWave = keyframes`
    from {
        opacity: 1;
        transform: scale(0);
    }

    to {
        opacity: 0;
        transform: scale(1);
    }
`;

export const AtomicWave = styled.div`
  position: relative;
  height: 30px;
  width: 30px;

  &:before,
  &:after {
    border-radius: 50%;
    content: '';
    position: absolute;
    transition:
      background-color ${(props) => props.theme.transition.theme},
      color ${(props) => props.theme.transition.theme};
  }

  &:before {
    background-color: ${(props) => adjustAlpha(props.theme.palette.spinner.default, 0.8)};
    height: 10px;
    width: 10px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

  &:after {
    animation: ${atomicWave} 2s infinite;
    border: 4px solid ${(props) => props.theme.palette.spinner.default};
    height: 100%;
    width: 100%;
    top: -4px;
    left: -4px;
  }
`;
