import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styles/styles.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ContextAndThemeProvider"] */ "/app/src/components/context/ContextAndThemeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/helper/StyledComponetsRegistry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/helper/TranslationsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/organisms/UnsavedChangesWatcher/UnsavedChangesWatcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/templates/DefaultTemplate/DefaultTemplate.tsx");
