'use client';

import { useCallback, useEffect, useRef, useState } from 'react';

import useRewardStore from '@Root/src/store/useRewardStore';

import { LocalStorageUserModel } from '@Api/localStorage/LocalStorageUserModel';
import ThemeModel from '@Api/models/ThemeModel';
import UserModel from '@Api/models/UserModel';
import { useGetUserMe, useUpdateProfileTheme } from '@Api/services/AccountService';
import { useListUserAchievementAllowedStatus } from '@Api/services/AchievementService';
import { useListThemes } from '@Api/services/ThemeService';

import { client, refresh } from '@Api-generated';

import { UserImageSrcModel } from '@Components/atoms/UserImage/UserImage';
import {
  deleteLocalStorageAuthorization,
  deleteLocalStorageUser,
  getLocalStorageAuthorization,
  getLocalStorageRefreshToken,
  setLocalStorageAuthorization,
  setLocalStorageRefreshToken,
  setLocalStorageUser,
} from '@Components/context/LocalStorage';
// import { CookieKey, getCookieValue } from '@Components/helper/cookie';
import { unexpectedApiError } from '@Components/helper/error';
import { jwtDecode } from '@Components/helper/jwt';
import { DEFAULT_LANGUAGE_ISO } from '@Components/helper/language';
import { errorToast } from '@Components/helper/toastHelper';

import {
  deleteCookieAuthorization,
  deleteCookieRefreshToken,
  deleteCookieUser, // setCookieAuthorization,
  setCookiesAuthorization,
  setCookieTheme,
  setCookieUser,
} from '../../api-next/cookie';

export function useGlobalContextHelper(
  lng: string,
  initialUser?: LocalStorageUserModel,
  initialThemeId?: string,
  initialThemes?: ThemeModel[]
) {
  const [isGlobalContextInitialized, setIsGlobalContextInitialized] = useState<boolean>(false);
  // todo: a ref is used here to avoid unwanted re-renderings.
  // The better solution would be to improve the re-render behaviour of the global context, but this should be a separate task after the alpha start
  const cookieUser = useRef(initialUser);

  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [user, setUser] = useState<LocalStorageUserModel | null>(null);

  const themesRef = useRef<ThemeModel[]>(initialThemes ?? []);
  const [themes, setThemes] = useState<ThemeModel[]>(initialThemes ?? []);
  const cookieThemeId = useRef<string | undefined>(initialThemeId);
  const [currentTheme, setCurrentTheme] = useState<ThemeModel | undefined>(
    initialThemes?.find((theme) => theme.id === initialThemeId)
  );

  const [authorizationHeader, setAuthorizationHeader] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [refreshTokenExpiration, setRefreshTokenExpiration] = useState<number | null>(null);
  const resetRewardStore = useRewardStore((state) => state.reset);

  // todo cookie approach
  // const getAuthenticationHeader = (): string | null | undefined => {
  //   let authData: string | null | undefined = authorizationHeader;
  //   console.log(authData);
  //   if (!authData) {
  //     authData = getCookieValue(CookieKey.Authorization);
  //   }
  //   return authData;
  // };
  // const getRefreshToken = (): string | null | undefined => {
  //   let token: string | null | undefined = refreshToken;
  //   if (!token) {
  //     token = getCookieValue(CookieKey.RefreshToken);
  //   }
  //   return token;
  // };

  const removeAuthorizationHeaderForRequests = (): void => {
    client.interceptors.request.use((request) => {
      request.headers.delete('Authorization');
      return request;
    });
  };

  const setAuthorizationHeaderForRequests = (authorizationHeader: string, refreshToken: string | undefined): void => {
    client.interceptors.request.use(async (request) => {
      if (refreshToken === undefined) {
        const token = getLocalStorageRefreshToken();
        if (token && token.refreshToken) {
          refreshToken = token.refreshToken;
        }
      }
      // dont call refresh on refresh
      if (request.url.includes('token/refresh')) {
        return request;
      }
      // todo we decode jwt twice. Can we streamline this? see: app/src/middleware.ts:48
      const decodedJwt = jwtDecode(authorizationHeader);
      const MAX_TIME_REFRESH = 60 * 1000;
      // if token expires in 1h -> refresh
      if (decodedJwt.exp - (Date.now() + MAX_TIME_REFRESH) / 1000 < 0) {
        if (refreshToken) {
          const refreshResponse = await refresh({
            body: {
              refresh_token: refreshToken,
            },
          });
          if (
            refreshResponse &&
            refreshResponse.data?.data.refreshToken &&
            refreshResponse.data?.data.refreshTokenExpiration
          ) {
            authorizationHeader = refreshResponse.data.data.token;
            setLocalStorageRefreshToken(
              refreshResponse.data.data.refreshToken,
              refreshResponse.data.data.refreshTokenExpiration
            );
            setLocalStorageAuthorization(authorizationHeader);
            setAuthorizationHeader(authorizationHeader);
          }
        }
      }
      request.headers.set('Authorization', 'Bearer ' + authorizationHeader);
      return request;
    });
  };

  // achievements
  const { executeRequest: listUserAchievementAllowedStatusExecuteRequest } = useListUserAchievementAllowedStatus(
    (result) => {
      const rewardMap: { [key: string]: boolean } = {};

      result.forEach((item) => (rewardMap[item.achievementType] = item.isAchieved));

      useRewardStore.setState({ rewardMap });
    },
    async (error) => {
      if (error.status === 403 || error.status === 401) {
        await logout();

        return;
      }
      errorToast(error);
    }
  );

  // theming
  const updateTheme = async (theme: ThemeModel) => {
    if (theme.colors === undefined || theme.themeType === undefined || theme.iconSet === undefined) {
      await setCurrentThemeById(theme.id);

      return;
    }

    setCurrentTheme(theme);
    cookieThemeId.current = theme.id;
    await setCookieTheme(theme.id);
  };

  const { executeRequest: listThemesExecuteRequest } = useListThemes(
    async (themes) => {
      setThemes(themes);
      themesRef.current = themes;

      if (themes.length === 0) {
        return;
      }

      await setCurrentThemeById(cookieThemeId.current, themes);
    },
    async (error) => {
      if (error.status === 403 || error.status === 401) {
        await logout();
        await fetchThemes();

        return;
      }
      errorToast(error);
    }
  );

  // todo: move to ssr with new app directory?
  const fetchThemes = useCallback(async () => {
    await listThemesExecuteRequest({});
  }, [listThemesExecuteRequest]);

  const { executeRequest: updateProfileThemeExecuteRequest } = useUpdateProfileTheme();

  const setCurrentThemeById = useCallback(
    async (themeId?: string, localThemes: ThemeModel[] = themes) => {
      const currentTheme = localThemes.find((theme) => theme.id === themeId);

      if (currentTheme === undefined) {
        if (localThemes.length === 0 && themes.length === 0) {
          await fetchThemes();

          return;
        }

        const primaryTheme = localThemes.find((theme) => theme.isPrimary === true);
        if (primaryTheme === undefined) {
          // should never happen
          throw Error('Unexpected Error: no primary theme found');
        }

        await updateTheme(primaryTheme);
        return;
      }

      await updateTheme(currentTheme);
    },
    [themes, fetchThemes]
  );

  const changeCurrentTheme = useCallback(
    async (theme: ThemeModel) => {
      await updateTheme(theme);

      if (loggedIn && theme.id && theme.id !== currentTheme?.id) {
        await updateProfileThemeExecuteRequest({ body: { themeId: theme.id } });
      }
    },
    [loggedIn, updateProfileThemeExecuteRequest, currentTheme?.id]
  );

  // get user data
  const { executeRequest: getUserExecuteRequest } = useGetUserMe(
    async (result) => {
      const lsAuth = getLocalStorageAuthorization();
      if (!lsAuth) {
        return;
      }
      const localUser = LocalStorageUserModel.hydrateFromObject(result, jwtDecode(lsAuth));
      setUser(localUser);
      await setCookieUser(localUser.serialize());
      cookieUser.current = localUser;
      setLocalStorageUser(localUser.serialize());

      await fetchThemes();

      if (themesRef.current.length === 0) {
        return;
      }

      setCurrentThemeById(result.theme?.id, themesRef.current).catch((reason) => new Error(reason));
    },
    async (error) => {
      if (error.status === 403 || error.status === 401) {
        await logout();

        return;
      }
      errorToast(error);
    }
  );

  const getUserData = useCallback(async () => {
    if (!cookieUser.current || !cookieThemeId.current) {
      await getUserExecuteRequest({});

      return;
    }

    setUser(cookieUser.current);
  }, [getUserExecuteRequest]);

  const reloadUser = async () => {
    await getUserExecuteRequest({});
  };

  const updateUserAsJson = async (userAsJson: string) => {
    await setCookieUser(userAsJson);
  };

  const updateUser = async (user: UserModel) => {
    if (!authorizationHeader) {
      // should never happen
      throw Error('Unexpected Error: authorizationHeader should exist');
    }
    if (!user.theme) {
      // should never happen
      throw Error('Unexpected Error: theme should exist in user');
    }

    const localStorageUser = LocalStorageUserModel.hydrateFromObject(user, jwtDecode(authorizationHeader));
    setUser(localStorageUser);
    await updateUserAsJson(localStorageUser.serialize());

    await updateTheme(user.theme);
  };

  const updateUsername = (username: string) => {
    if (!user) {
      // should never happen
      throw Error('Unexpected Error: user should exist');
    }
    if (!authorizationHeader) {
      // should never happen
      throw Error('Unexpected Error: authorizationHeader should exist');
    }

    const localStorageUser = LocalStorageUserModel.hydrateFromObject(
      {
        ...user,
        username,
      },
      jwtDecode(authorizationHeader)
    );
    setUser(localStorageUser);
    updateUserAsJson(localStorageUser.serialize());
  };

  const updateGravatar = (gravatar: UserImageSrcModel) => {
    if (!user) {
      // should never happen
      throw Error('Unexpected Error: user should exist');
    }
    if (!authorizationHeader) {
      // should never happen
      throw Error('Unexpected Error: authorizationHeader should exist');
    }

    const localStorageUser = LocalStorageUserModel.hydrateFromObject(
      {
        ...user,
        gravatar,
      },
      jwtDecode(authorizationHeader)
    );
    setUser(localStorageUser);
    updateUserAsJson(localStorageUser.serialize());
  };

  const login = useCallback(
    async (authorizationHeader: string, refreshTokenResponse?: string, refreshTokenExpirationResponse?: number) => {
      setLoggedIn(true);
      setAuthorizationHeader(authorizationHeader);
      if (refreshTokenResponse && refreshTokenExpirationResponse) {
        setRefreshToken(refreshTokenResponse);
        setRefreshTokenExpiration(refreshTokenExpirationResponse);
        setLocalStorageRefreshToken(refreshTokenResponse, refreshTokenExpirationResponse);
      }
      setLocalStorageAuthorization(authorizationHeader);
      // store in cookie, to have this also on SSR
      await setCookiesAuthorization(authorizationHeader, refreshTokenResponse, refreshTokenExpirationResponse);
      setAuthorizationHeaderForRequests(authorizationHeader, refreshTokenResponse);
      await getUserData();
      await listUserAchievementAllowedStatusExecuteRequest({});
    },
    [getUserData, listUserAchievementAllowedStatusExecuteRequest]
  );

  const logout = async () => {
    setUser(null);
    setAuthorizationHeader(null);
    setRefreshTokenExpiration(null);
    setRefreshToken(null);
    removeAuthorizationHeaderForRequests();
    deleteLocalStorageAuthorization();
    deleteLocalStorageUser();

    // only send requests to delete cookies if they have been set to improve performance
    if (cookieUser.current) {
      await deleteCookieUser();
      cookieUser.current = undefined;
      await deleteCookieRefreshToken();
      await deleteCookieAuthorization();
    }

    setLoggedIn(false);
    resetRewardStore();
  };

  const initialize = useCallback(async () => {
    client.interceptors.request.use((request) => {
      request.headers.set('X-Locale', lng ?? DEFAULT_LANGUAGE_ISO);
      request.headers.set('X-Timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);
      return request;
    });
    const localStorageAuthorization = getLocalStorageAuthorization();
    if (localStorageAuthorization) {
      await login(localStorageAuthorization);
    } else {
      await logout();
    }

    if (!cookieThemeId.current || !currentTheme) {
      await setCurrentThemeById(cookieThemeId.current);
    }

    setIsGlobalContextInitialized(true);
  }, [login]);

  useEffect(() => {
    initialize().catch(unexpectedApiError);
  }, [initialize]);

  return {
    isGlobalContextInitialized,
    authorizationHeader,
    refreshToken,
    refreshTokenExpiration,
    loggedIn,
    login,
    logout,
    user,
    updateUser,
    updateUsername,
    updateGravatar,
    reloadUser,
    themes,
    fetchThemes,
    currentTheme,
    changeCurrentTheme,
  };
}
