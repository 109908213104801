import styled, { css } from 'styled-components';

import { Icon } from '@Components/atoms/Icon/Icon';
import Link from '@Components/atoms/Link';
import MenuButton from '@Components/atoms/MenuButton/MenuButton';
import Row from '@Components/atoms/Row';
import Text from '@Components/atoms/Text';
import UserImage from '@Components/atoms/UserImage';
import { StyledImage } from '@Components/atoms/UserImage/UserImage.styles';
import { fadeIn } from '@Components/helper/animation';
import Logo from '@Components/molecules/Logo/Logo';

export const headerHeight = '62px';

export const Root = styled.header`
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 110;

  background-color: ${(props) => props.theme.palette.background.header};
  transition: background-color ${(props) => props.theme.transition.theme};
`;

export const HeaderRow = styled(Row)`
  height: ${headerHeight};
`;

export const Content = styled(Row)`
  @media (max-width: ${(props) => props.theme.device.tablet}) {
    gap: 1.5rem;
  }
`;

export const PulseIndicatorWrapper = styled.div`
  ${fadeIn(2)}
  cursor: pointer;
`;

export const VisitorsOnline = styled.div`
  ${fadeIn(1)};
`;

export const VisitorCounter = styled(Text)`
  min-width: 2.5rem;
`;

export const TabletDesktopLogo = styled(Logo)`
  display: flex;
  align-self: center;

  @media (max-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`;

export const MobileLogo = styled(Logo)`
  display: flex;
  align-self: center;

  @media (min-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`;

export const LanguageIcon = styled(Icon)`
  @media (max-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`;

export const StyledRow = styled(Row)`
  @media (max-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`;

export const UserRow = styled(Row)`
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      div {
        transition: border ${(props) => props.theme.transition.theme};
        border-color: ${(props) => props.theme.palette.text.themePrimary};
      }

      p {
        color: ${(props) => props.theme.palette.text.themePrimary};
      }
    }
  }
`;

export const Username = styled(Text)`
  @media (max-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`;

export const UserImageWrapper = styled.div`
  position: relative;
`;

export const StyledUserImage = styled(UserImage)<{ $isOpen: boolean }>`
  ${StyledImage} {
    transition: border ${(props) => props.theme.transition.theme};
    ${(props) =>
      props.$isOpen &&
      css`
        border: 1px solid ${props.theme.palette.text.themePrimary};
      `}
  }
`;

export const Counter = styled.div`
  position: absolute;
  bottom: -5px;
  right: -10px;
  background-color: ${(props) => props.theme.palette.badge.primary.background};
  color: ${(props) => props.theme.palette.badge.primary.text};
  padding: 3px 7px;
  border-radius: 60%;
  font-size: 10px;
  font-weight: ${(props) => props.theme.font.text.semibold.small.fontWeight};
  font-family: ${(props) => props.theme.font.primaryFamily};
`;

export const StyledMenuButton = styled(MenuButton)`
  @media (min-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`;

export const GameModes = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;

  &:hover {
    p {
      color: ${(props) => props.theme.palette.text.themePrimary};
    }
  }

  @media (max-width: ${(props) => props.theme.device.tablet}) {
    display: none;
  }
`;

export const GameModesText = styled(Text)`
  &:hover {
    color: ${(props) => props.theme.palette.text.themePrimary};
  }
`;

export const LoginSignupLink = styled(Link)`
  color: ${(props) => props.theme.palette.text.header};

  &:hover {
    color: ${(props) => props.theme.palette.text.themePrimary};
  }
`;
