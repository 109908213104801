'use client';

import isPropValid from '@emotion/is-prop-valid';
import dynamic from 'next/dynamic';
import React from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { WebTarget } from 'styled-components/dist/types';
import { LocalStorageUserModel } from '@Api/localStorage/LocalStorageUserModel';
import ThemeModel from '@Api/models/ThemeModel';
import { ThemeResponse } from '@Api-generated';
import { client } from '@Api-generated';
import ToastContainer from '@Components/atoms/ToastContainer/ToastContainer';
import { MyGlobalContext } from '@Components/context/GlobalContext';
import { useGlobalContextHelper } from '@Components/context/useGlobalContextHelper';
import ColyseusContainer from '@Components/molecules/ColyseusContainer/ColyseusContainer';
import { getDefaultTheme } from '@Assets/styles/theme/theme';
const NotificationToastContainer = dynamic(() => import('@Components/molecules/NotificationToastContainer'), {
  ssr: false
});
interface Props {
  lng: string;
  user?: string;
  authorization?: string;
  themeId?: string;
  themes?: ThemeResponse[];
  children: React.ReactNode;
}
const ContextAndThemeProvider = (props: Props): React.ReactElement => {
  const {
    children,
    lng,
    user,
    authorization,
    themes,
    themeId
  } = props;
  const userModel = user && authorization ? LocalStorageUserModel.hydrateFromJson(user, authorization) : undefined;
  client.setConfig({
    baseUrl: process.env.NEXT_PUBLIC_API_ROOT_URL ?? ''
  });
  const initialThemesModel = themes?.map(theme => ThemeModel.hydrateFromApi(theme));
  const globalContext = useGlobalContextHelper(lng, userModel, themeId, initialThemesModel);
  const theme = getDefaultTheme(globalContext.currentTheme);

  // todo: tmp solution => we should migrate to transient props (https://styled-components.com/docs/api#transient-props).
  //  Visit https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default for more information
  const shouldForwardProp = (prop: string, target: WebTarget) => {
    if (typeof target === 'string') {
      return isPropValid(prop);
    }
    return true;
  };
  const renderToasts = () => {
    if (globalContext.loggedIn) {
      return <NotificationToastContainer>
          <ToastContainer />
        </NotificationToastContainer>;
    }
    return <ToastContainer data-sentry-element="ToastContainer" data-sentry-component="renderToasts" data-sentry-source-file="ContextAndThemeProvider.tsx" />;
  };
  return <MyGlobalContext.Provider value={globalContext} data-sentry-element="unknown" data-sentry-component="ContextAndThemeProvider" data-sentry-source-file="ContextAndThemeProvider.tsx">
      <StyleSheetManager shouldForwardProp={shouldForwardProp} data-sentry-element="StyleSheetManager" data-sentry-source-file="ContextAndThemeProvider.tsx">
        <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-source-file="ContextAndThemeProvider.tsx">
          {children}
          {renderToasts()}
          <ColyseusContainer data-sentry-element="ColyseusContainer" data-sentry-source-file="ContextAndThemeProvider.tsx" />
        </ThemeProvider>
      </StyleSheetManager>
    </MyGlobalContext.Provider>;
};
export { ContextAndThemeProvider };