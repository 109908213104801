'use client';

import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '@Components/atoms/Text';
import { PropsWithClassName } from '@Components/helper';
import ConfirmationModal from '@Components/molecules/ConfirmationModal/ConfirmationModal';
import { TextAlign, TextSize } from '@Helpers/types/text';
interface Props extends PropsWithClassName {
  onAccept: () => void;
  onAbort: () => void;
}
const UnsavedChangesModal = (props: Props): React.ReactElement => {
  const {
    onAccept,
    onAbort
  } = props;
  const {
    t
  } = useTranslation('global');
  return <ConfirmationModal dataTestId={props.dataTestId ?? 'unsaved-changes-modal'} className={props.className} onAccept={onAccept} onAbort={onAbort} acceptText={t('unsaved_changes_modal.accept')} abortText={t('unsaved_changes_modal.abort')} data-sentry-element="ConfirmationModal" data-sentry-component="UnsavedChangesModal" data-sentry-source-file="UnsavedChangesModal.tsx">
      <Text size={TextSize.Medium} textAlign={TextAlign.Center} data-sentry-element="Text" data-sentry-source-file="UnsavedChangesModal.tsx">
        {t('unsaved_changes_modal.text')}
      </Text>
    </ConfirmationModal>;
};
export default UnsavedChangesModal;