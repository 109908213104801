'use client';

import { useRouter } from 'next/navigation';
import React, { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { PropsWithClassName } from '@Components/helper';
import UnsavedChangesModal from '@Components/molecules/UnsavedChangesModal/UnsavedChangesModal';
import useUnsavedChangesStore from '@Store/useUnsavedChangesStore';
const UnsavedChangesWatcher = (props: PropsWithClassName): React.ReactElement => {
  const router = useRouter();
  const {
    pendingRoute,
    isNavigationBlocked,
    setPendingRoute,
    setIsNavigationBlocked
  } = useUnsavedChangesStore(useShallow(state => ({
    pendingRoute: state.pendingRoute,
    isNavigationBlocked: state.isNavigationBlocked,
    setPendingRoute: state.setPendingRoute,
    setIsNavigationBlocked: state.setIsNavigationBlocked
  })));
  useEffect(() => {
    if (isNavigationBlocked) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = ''; // deprecated but used to support older browsers;
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [isNavigationBlocked]);
  const handleAccept = (route: string) => {
    router.push(route, undefined);
    setPendingRoute(null);
    setIsNavigationBlocked(false);
  };
  const handleAbort = () => {
    setPendingRoute(null);
  };
  if (pendingRoute === null) {
    return <></>;
  }
  return <UnsavedChangesModal className={props.className} onAccept={() => handleAccept(pendingRoute)} onAbort={handleAbort} data-sentry-element="UnsavedChangesModal" data-sentry-component="UnsavedChangesWatcher" data-sentry-source-file="UnsavedChangesWatcher.tsx" />;
};
export default UnsavedChangesWatcher;