import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface CasualMatchLobbyState {
  isSomeoneWaitingForPlayers: boolean;
}

interface CasualMatchLobbyAction {
  setIsSomeoneWaitingForPlayers: (isSomeoneWaitingForPlayers: boolean) => void;
}

const useGlobalColyseusRoomStore = create<CasualMatchLobbyState & CasualMatchLobbyAction>()(
  devtools((set): CasualMatchLobbyState & CasualMatchLobbyAction => ({
    isSomeoneWaitingForPlayers: false,
    setIsSomeoneWaitingForPlayers: (isSomeoneWaitingForPlayers) => {
      set({
        isSomeoneWaitingForPlayers,
      });
    },
  }))
);
export default useGlobalColyseusRoomStore;
