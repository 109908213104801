import * as Colyseus from 'colyseus.js';
import { Room, RoomAvailable } from 'colyseus.js';
import { useEffect, useMemo, useState } from 'react';

import { useGlobalContext } from '@Components/context/GlobalContext';
import { MultiplayerCode, multiplayerConfig, MultiplayerMode } from '@Components/helper/colyseus/colyseusHelper';
import { errorToast } from '@Components/helper/toastHelper';

import useCasualMatchLobbyStore from '@Store/useCasualMatchLobbyStore';

import useRouter from '@Helpers/i18n/useRouter';

interface LobbyRoomAvailable extends RoomAvailable {
  locked: boolean;
}

export const useCasualMatchLobby = () => {
  const client = useMemo(() => new Colyseus.Client(process.env.NEXT_PUBLIC_WS_ROOT_URL), []);
  const router = useRouter();
  const { loggedIn } = useGlobalContext();

  const [lobby, setLobby] = useState<Room | null>(null);
  const [allRooms, setAllRooms] = useState<LobbyRoomAvailable[]>([]);

  const { setIsSomeoneWaitingForPlayers } = useCasualMatchLobbyStore((state) => ({
    setIsSomeoneWaitingForPlayers: state.setIsSomeoneWaitingForPlayers,
  }));

  useEffect(() => {
    setIsSomeoneWaitingForPlayers(allRooms.some((room) => !room.locked && room.clients < room.maxClients));
  }, [allRooms, setIsSomeoneWaitingForPlayers]);

  useEffect(() => {
    if (loggedIn) {
      const joinLobby = async () => {
        const lobbyRoom = await client.joinOrCreate(multiplayerConfig[MultiplayerMode.CasualMatchLobby].name);
        setLobby(lobbyRoom);
      };

      joinLobby().catch((error) => errorToast(error));
    }
  }, [client, loggedIn]);

  useEffect(() => {
    return () => {
      lobby?.leave();
    };
  }, [lobby]);

  useEffect(() => {
    if (!lobby) {
      return;
    }

    lobby.removeAllListeners();

    lobby.onLeave((code) => {
      switch (code) {
        case MultiplayerCode.DefaultDisconnect:
        case MultiplayerCode.ConsentedDisconnect:
          break;
        case MultiplayerCode.UnexpectedError:
        default:
          throw Error(`Unexpected global colyseus room disconnect with code [${code}]`);
      }
    });

    lobby.onMessage('rooms', (rooms: LobbyRoomAvailable[]) => {
      setAllRooms(rooms.filter((room) => room.roomId !== multiplayerConfig[MultiplayerMode.GlobalRoom].name));
    });

    lobby.onMessage('+', ([roomId, room]) => {
      setAllRooms((prevRooms) => {
        const roomIndex = prevRooms.findIndex((r) => r.roomId === roomId);
        if (roomIndex === -1) {
          return [...prevRooms, room];
        } else {
          const updatedRooms = [...prevRooms];
          updatedRooms[roomIndex] = { ...room };
          return updatedRooms;
        }
      });
    });

    lobby.onMessage('-', (roomId) => {
      setAllRooms(allRooms.filter((room) => room.roomId !== roomId));
    });
  }, [allRooms, lobby, router]);
};
